<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-toolbar flat>
      <v-toolbar-title>
        <v-icon left>mdi-animation</v-icon> Attributes
      </v-toolbar-title>
    </v-toolbar>
    <v-divider/>
    <v-expansion-panels multiple tile accordion flat :value="expanded">
      <v-expansion-panel v-for="attr_group in partyAttributeGroups" :key="attr_group.id">
        <v-divider/>
        <v-expansion-panel-header class="grey lighten-3">
          {{attr_group.name}}
        </v-expansion-panel-header>
        <v-divider/>
        <v-expansion-panel-content>
          <v-row v-for="(row, index) in getAttributesForGroup(attr_group.id)" :key="index" align="center" dense>
            <v-col cols="3" v-for="attr in row" :key="attr.id">
              <AttrInput
                :ref="`attr-${attr.id}`"
                :attr="attr"
                :attrvalue="getAttributeValueForAttribute(attr)"
                :attrvalues="getAttributeValuesForAttribute(attr)"
                :defaultStartDate="defaultStartDate"
                :hasWriteAccess="hasWriteAccess"
                @save-attr="saveAttribute"
                @update-attr="updateAttribute"
                @refresh-values="getPartyAttributes">
              </AttrInput>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-form>
</template>
<script>
// API
import attrApi from '../../axios/party-attribute-endpoint'

// Mixins
import { displayAlert } from '@/mixins/alert'
import { formValidation } from '@/mixins/formvalidation'
import { defaultStartDate } from '@/mixins/default-date'
import { userAccess } from '@/mixins/user-access'

// Components
import AttrInput from '@/components/parties/attr_input.vue'
export default {
  name: 'AttributeComponentNew',
  data () {
    return {
      expanded: Array.from(Array(50).keys()),
      partyAttributes: [],
      partyAttributeGroups: {},
      partyAttributeValues: [],
      attrInfo: false,
      loading: true,
      defaultStartDate: null
    }
  },
  components: { AttrInput },
  props: {
    value: String,
    attributeid: String
  },
  watch: {
    attributeid: {
      handler (newValue) {
        if (newValue) {
          this.getPartyAttributes()
        }
      }
    }
  },
  mixins: [displayAlert, formValidation, defaultStartDate, userAccess],
  async created () {
    if (this.attributeid) {
      await this.getPartyAttributes()
    }
  },
  computed: {
    currentDate () {
      return this.moment().format('YYYY-MM-DD')
    },
    requiredAttributes () {
      return this.partyAttributes.filter(attr => attr && attr.is_required)
    }
  },
  methods: {
    checkRequiredAttributes () {
      if (this.requiredAttributes.length > 0) {
        const missing = this.requiredAttributes.filter(attr => {
          const vals = this.getAttributeValuesForAttribute(attr)
          if (vals.length === 0) return attr
        })
        if (missing.length > 0) {
          return missing.map(attr => attr.name)
        }
        return false
      }
      return false
    },
    sortByDisplayOrder (a, b) {
      if (a.attr_group.display_order > b.attr_group.display_order) return 1
        if (a.attr_group.display_order < b.attr_group.display_order) return -1
        if (a.display_order > b.display_order) return 1
        if (a.display_order < b.display_order) return -1
    },
    async getPartyAttributes () {
      if (!this.attributeid) return
      this.loading = true
      let attrs = []
      try {
        const res = await attrApi.attribute.getList(this.attributeid, 100, 0)
        if (res?.data?.length > 0) {
          attrs = res.data
          if (!this.isIproAdmin) {
            attrs = attrs.filter(item => item.attr_group.constant !== 'IPRO_ADMIN' && item.attr_group.constant !=='MOBILE_PREFERENCES')
          }
          if (!this.isAdmin) {
            attrs = attrs.filter(item => item.attr_group.constant !== 'JBG_ADMIN')
          }
          attrs.sort(this.sortByDisplayOrder)
        }
        this.partyAttributes = attrs
        this.partyAttributeGroups =
          this.partyAttributes.reduce((acc, curr, idx, arr) => {
            if (idx === 0 || curr.attr_group.id !== arr[idx - 1].attr_group.id) {
              acc.push(curr.attr_group)
            }
          return acc
          }, [])
          this.getPartyAttributeValues()
        } catch (err) {
          this.handleError(err)
        } finally {
          this.loading = false
        }
    },
    async getPartyAttributeValues () {
      const id = this.$route.query.id
      try {
        this.partyAttributeValues = []
        const response = await attrApi.attributeValue.getList(id, 100, 0)
        this.partyAttributeValues = response.data
        const defaultDate = this.getPartyDefaultStartDate({ values: this.partyAttributeValues })
        if (defaultDate && !this.defaultStartDate) {
          this.defaultStartDate = defaultDate
        }
        const hasDefault = this.partyAttributes.flatMap(attr => {
          if (attr.default_value || attr.default_key_value) {
            const vals = this.getAttributeValuesForAttribute(attr)
            if (vals.length === 0) {
              return { id: attr.id, defaultVal: (attr.default_value || attr.default_key_value) }
            }
          }
          return []
        })
        if (hasDefault.length > 0) {
          await Promise.allSettled(hasDefault.map(attr => {
            const ref = this.$refs[`attr-${attr.id}`][0]
            let val = attr.defaultVal
            if (['true', 'false'].includes(val)) {
              val = JSON.parse(val)
            }
            const data = ref.buildSavePayload(val)
            return attrApi.attributeValue.post(data)
          })).catch(err => {
            this.handleError(err)
          })
          this.getPartyAttributes()
        }
      } catch (err) {
        this.handleError(err)
      }
    },
    getAttributesForGroup (attrGroupId) {
      const numCols = 4
      const result = this.partyAttributes.filter(attrValue => attrValue.attr_group.id === attrGroupId)
      // Breaks Attributes into 3 attributes per row
      const group = result.reduce(function (acc, curr, index) {
        const i = index
        const row = Math.floor(i / numCols)
        const mod = i % numCols
        if (mod === 0) {
          acc.push([])
        }
        acc[row].push(curr)
        return acc
      }, [])
      return group
    },
    getAttributeValueForAttribute (attr) {
      const results = this.getAttributeValuesForAttribute(attr)
      var value = null
      results.forEach(attrVal => {
        var isAttributeActive =
          (results.length > 0 && attrVal.end_dt === null && this.currentDate >= attrVal.start_dt) ||
          (results.length > 0 && attrVal.end_dt !== null && this.currentDate >= attrVal.start_dt && this.currentDate <= attrVal.end_dt)

        if (!isAttributeActive && attrVal.attr.is_time_sensitive === false) {
          isAttributeActive = true
        }

        if (isAttributeActive) {
          value = attrVal
        }
      })
      if (!value && results.length > 0) {
        value = results[0]
      }
      return value
    },
    getAttributeValuesForAttribute (attr) {
      return this.partyAttributeValues.filter(attrValue => attrValue.attr.id === attr.id)
    },
    async saveAttribute (attr, attrValueObj) {
      try {
        await attrApi.attributeValue.post(attrValueObj)
        this.emitAlert(true, 'success', `${attr.name} has been successfully saved.`)
        this.getPartyAttributes()
      } catch (err) {
        this.handleError(err)
      }
    },
    async updateAttribute (attrvalue, attrValueObj) {
      try {
        await attrApi.attributeValue.put(attrvalue.id, attrValueObj)
        this.emitAlert(true, 'success', `${attrvalue.attr.name} has been successfully updated.`)
        this.getPartyAttributes()
      } catch (err) {
        this.handleError(err)
      }
    }
  }
}
</script>
