<template>
<v-form>
  <v-container fluid class="ma-0 pa-0">
    <v-data-table
      id="partiesList"
      :headers="headers"
      :height="tableSize"
      :items-per-page.sync="pageSize"
      :items.sync="parties"
      :loading="loading"
      hide-default-footer
      fixed-header
      dense
      @click:row="editParty">
      <template v-slot:top>
        <v-toolbar flat color="toolbar" style="cursor: default;">
          <v-toolbar-title class="ml-2 title">Parties</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-btn
            id="createParty"
            v-if="hasWriteAccess"
            color="primary"
            class="mr-4"
            @click="createParty">
              Create Party
            </v-btn>
          <v-spacer></v-spacer>
          <v-col style="max-width: 800px">
          <SearchFilters v-model="searchParams" />
          </v-col>
        </v-toolbar>
      </template>
      <template v-slot:[`item.addresses`]="{ item }">
        <span v-if="item.billing_address">
          {{ getBillingAddress(item.billing_address) }}
        </span>
      </template>
      <template v-slot:[`item.created_on`]="{ item }">
        <span v-if="item.created_on != null">
          {{ moment(item.created_on).format($config.timestamp) }}
        </span>
      </template>
      <template v-slot:[`item.updated_on`]="{ item }">
        <span v-if="item.updated_on != null">
          {{ moment(item.updated_on).format($config.timestamp) }}
        </span>
      </template>
      <template v-slot:[`item.attributes.USER_BLOCKED`]="{ item }">
        <v-icon 
          v-if="getIsBlocked(item)" 
          small 
          color="error">
          mdi-block-helper
        </v-icon>
      </template>
      <template v-slot:footer>
        <PagingFooter
          :pageSizes="pageSizes"
          :page="page"
          :disablePrevious="disablePrevious"
          :disableNext="disableNext"
          :showFirstPage="showFirstPage"
          @setPageSize="setPageSize"
          @first="setFirstPage"
          @previous="previous"
          @next="next">
        </PagingFooter>
      </template>
    </v-data-table>
  </v-container>
  </v-form>
</template>
<script>
// api
import Party from '@/axios/party-endpoint'

// components
import SearchFilters from '@/components/SearchFilters.vue'
import PagingFooter from '@/components/shared/PagingFooter'

// third party
import { debounce } from 'lodash'

// mixins
import { fullwidth } from '@/mixins/fullwidth'
import { userAccess } from '@/mixins/user-access'
import { displayAlert } from '@/mixins/alert'
import { partySearch } from '@/mixins/party-search'
export default {
  name: 'Parties',
  data () {
    return {
      loading: true,
      expanded: [],
      from: 0,
      view_dialog: false,
      pageSize: 50,
      pageSizes: [25, 50, 100, 200],
      page: 1,
      headers: [
        {value: 'data-table-expand', sortable: false, class: 'accent'},
        {text: 'Party Name', sortable: true, filterable: true, value: 'name', align: 'left', class: 'accent white--text'},
        {text: 'Party Type', sortable: true, filterable: true, value: 'party_type.name', align: 'center', class: 'accent white--text', width: "7rem"},
        {text: 'Party Email', sortable: true, filterable: true, value: 'email', align: 'center', class: 'accent white--text'},
        {text: 'Billing Address', sortable: true, filterable: true, value: 'addresses', align: 'center', class: 'accent white--text', width: "10rem"},
        /* { text: 'Created By', sortable: false, filterable: true, value: 'created_by', align: 'center'}, */
        {text: 'Updated By', sortable: true, filterable: true, value: 'updated_by', align: 'center', class: 'accent white--text'},
        /* {text: 'Created On', sortable: false, filterable: true, value: 'created_on', align: 'center'}, */
        {text: 'Updated On', sortable: true, filterable: true, value: 'updated_on', align: 'center', class: 'accent white--text'},
        {text: 'Blocked User', sortable: true, filterable: false, value: 'attributes.USER_BLOCKED', align: 'center', class: 'accent white--text', width: "8rem"}
      ],
      parties: [],
      search: '',
      searchParams: {},
      serverItemsLength: 0,
      showexpand: false,
      queryParts: {
        must: [],
        must_not: []
      }
    }
  },
  watch: {
    page: {
      handler (newValue) {
        if (newValue > 1) {
          this.from = (newValue * this.pageSize) - this.pageSize
        } else {
          this.from = 0
        }
        this.searchParties()
      }
    },
    search: {
      handler () {
        this.page = 1
        this.persistSearch()
      }
    },
    searchParams: {
      handler: debounce(function (newValue) {
        if (newValue) {
          if (this.page > 1) {
            this.page = 1
          } else {
            this.searchParties()
          }
        }
      }, 500),
      deep: true
    }
  },
  mixins: [fullwidth, userAccess, displayAlert, partySearch],
  components: { SearchFilters, PagingFooter },
  filters: {
    stripExtraNonChar: (value) => {
      if (!value) return ''
      if (value.includes('.')) {
        value = value.split('.')
        const valLength = value.length
        value = value[(valLength - 1)]
      }
      if (value.includes('_')) {
        value = value.replace(/_/g, ' ')
      }
      value = value.toLowerCase()
      return value
    }
  },
  beforeCreate () {
    this.$store.dispatch('getKeyValues')
  },
  async created () {
    this.nonTableHeight = 218
    if (this.limitAccessByUserParties && this.userRelatedPartyIds.length === 0) {
      await this.$store.dispatch('getUserRelatedParties')
    }
    if (this.activeSessionSearch === false) {
      this.searchParties()
    }
  },
  computed: {
    disablePrevious () {
      return (this.page - 1) === 0
    },
    disableNext () {
      return this.loading || (this.parties.length < this.pageSize)
    },
    showFirstPage () {
      return this.page > 1
    },
    party_types () {
      return this.$store.getters.party_types
    },
    activeSessionSearch () {
      const keys = Object.keys(sessionStorage).filter(key => key?.includes('filter'))
      return (keys.length > 0) && keys.some(key => {
        const value = sessionStorage[key]
        return Array.isArray(value) ? (value.length > 0) : Boolean(value)
      })
    },
    attrFilters () {
      return ['WHOLESALER_STORE_NUMBER', 'VENDOR_ID']
    },
    searchPayload () {
      const attrs = {}
      const filtersObj = Object.keys(this.searchParams).reduce((obj, key) => {
        if (this.attrFilters.includes(key)) {
          attrs[key] = this.searchParams[key]
        } else {
          obj[key] = this.searchParams[key]
        }
        return obj
      }, {})
      filtersObj.attributes = attrs

      return filtersObj
    }
  },
  methods: {
    async searchParties () {
      const payload = this.buildSearchPayload()
      let parties = []
      this.loading = true
      try {
        if (!(this.limitAccessByUserParties && payload.related_party_ids?.length == 0)){
          const res = await Party.searchParties(payload, this.from, this.pageSize)
          if (res?.data?.length > 0) {
            parties = res.data.map(p => this.formatParty(p))
          }
        }
        this.parties = parties
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    },
    buildSearchPayload () {
      let payload = {}
      if (Object.entries(this.searchPayload).length > 0) {
        payload = this.searchPayload
      }
      if (this.limitAccessByUserParties ){
        payload.related_party_ids = this.userRelatedPartyIds
      }
      return payload
    },
    clearSearch () {
      this.search = ''
      this.showexpand = false
      sessionStorage.removeItem('ipro_partysearch')
    },
    persistSearch () {
      sessionStorage.setItem('ipro_partysearch', this.search)
    },
    previous () {
      this.page -= 1
      if (this.page < 1) {
        this.page = 1
      }
    },
    next () {
      this.page += 1
    },
    setPageSize(newValue) {
      this.pageSize = newValue
      if (this.page == 1) {
        this.searchParties()
      }
      this.page = 1
    },
    setFirstPage () {
      this.page = 1
    },
    createParty () {
      this.$router.push({ name: 'Parties Create' })
    },
    editParty (party) {
      this.$router.push({ name: 'Parties Edit', query: { id: party.id } })
    },
    openSearchInfo () {
      this.view_dialog = true
    },
    getBillingAddress (billing_address) {
      return billing_address ? `${billing_address.city}, ${billing_address.state_province.kv_constant}` : ''
    },
    getFirstHighlight (highlight) {
      if (highlight) {
        return Object.fromEntries([Object.entries(highlight)[0]])
      }
    },
    getAttributes (item) {
      const details = this.partyTypeDetails(item.party_type)
      if (details.length > 0) {
        return details.map(section => {
          return section.flatMap(detail => {
            const attr = item.attrs.find(attr => Object.keys(attr).includes(`${detail.key}`))
            if (attr) {
              const result = { key: detail.text, value: attr[`${detail.key}`] }
              if (detail.key.includes('_DATE')) {
                result.value = this.moment(result.value).format(this.$config.date_display)
              }
              return result
            }
            return []
          })
        })
      }
      return details
    },
    getIsBlocked (item)
    {
      // If no attributes, bail
      if (item.attributes == null)
        return false

      // If user blocked attribute doesn't exist, bail
      if (!("USER_BLOCKED" in item.attributes))
        return false

      return item.attributes.USER_BLOCKED.toLowerCase() === "true"
    },
    // this logic is essentially a placeholder and should be moved elsewhere
    partyTypeDetails (party_type) {
      if (party_type.constant === 'STORE') {
        return [
          [
            {
              text: 'Wholesaler Store Number',
              key: 'WHOLESALER_STORE_NUMBER'
            },
            {
              text: 'S4 Store Number',
              key: 'S4_STORE_NUMBER'
            }
          ],
          [
            {
              text: 'Store Begin Date',
              key: 'BEGIN_DATE'
            },
            {
              text: 'Store End Date',
              key: 'END_DATE'
            }
          ]
        ]
      }
      return []
    }
  }
}
</script>
<style scoped lang="scss">
.searchSnippet {
  margin: 5px 20px,
}
.capitalize {
  text-transform: capitalize;
}
::v-deep tbody tr.v-data-table__expanded__content {
  background: #efefef;
  box-shadow: none !important;
}

</style>
